/* eslint-disable @next/next/no-img-element */
// backdrop-blur-[3px]

import { Button } from '@/components';

// pages/500.js
export default function Custom500({ errorCode, errorMessage }) {
  return (
    <div className="h-screen flex items-center justify-center relative">
      <div className="w-full h-full max-h-screen ">
        <img
          src="/500Page/500Page.png"
          width={2880}
          height={1800}
          quality={100}
          alt="500 Page Background"
          className="object-cover xxl:object-bottom h-full w-full"
        />
      </div>
      {/* <div className="gradient absolute left-0 top-0 w-full h-full  "></div> */}

      <div className="text-wrapper absolute bg-black/30 backdrop-blur-[3px] m-4 rounded-[7px] lg:w-[750px] pt-4 pb-8 lg:px-4">
        <h1 className="font-iviy-ora text-center text-title-l not-italic font-normal leading-60 -tracking-normal  text-secondary-coolsand">
          {errorCode} <br />
          {errorMessage}
        </h1>

        <Button
          theme="light"
          link={{ text: 'Back to Home', href: '/', linkType: 'Content', target: null }}
          type="primary"
          className="w-fit mx-auto mt-10"
        ></Button>
      </div>
    </div>
  );
}
